import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import './styles.css';
import { bars } from "./data"
import { mapStyles } from "./mapStyles";
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';

const locationCache = {};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: mapStyles,
};

const centers = {
  lat: 43.6532,
  lng: -79.3832,
};

// const appid = process.env.REACT_APP_OPEN_WEATHER_API_KEY;
// const mapsId = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const appid = '89c589ee874841a887908666255f0544';
const mapsId = 'AIzaSyAXDWlYSixx8Zz4kihJTgW8nnpDgB21PyU';

const iconMap = {
  '01d': 'sun.png',
  '01n': 'sun.png',
  '02d': 'clouds.png',
  '02n': 'clouds.png',
  '03d': 'clouds.png',
  '03n': 'clouds.png',
  '04d': 'clouds.png',
  '04n': 'clouds.png',
  '09d': 'rain.png',
  '09n': 'rain.png',
  '10d': 'rain.png',
  '10n': 'rain.png',
  '11d': 'thunderstorm.png',
  '11n': 'thunderstorm.png',
  '50d': 'mist.png',
  '50n': 'mist.png',
  '13d': 'sun.png',
  '13n': 'sun.png',
};


export default function Home() {
  const [libraries] = useState(['places']);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: mapsId,
    libraries: libraries,
  });
  
  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const [barWeatherData, setBarWeatherData] = useState({});
  const [markers, setMarkers] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [mapBounds, setMapBounds] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedTree, setSelectedTree] = useState(null);
  const [selectedBar, setSelectedBar] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [locationPermission, setLocationPermission] = useState(false);
  const [selected, setSelected] = useState(null);
  const [openInfoWindow, setOpenInfoWindow] = useState(null);
  const [sidebarBars, setSidebarBars] = useState([]);
  const center = useRef(); // Ref for the map instance
  
  const handleMapLoad = useCallback((map) => {
    addMarkers(map, setSelectedTree, setMarkers, setSelectedBar, openInfoWindow, setOpenInfoWindow);
    center.current = map; // Store the map instance in the ref
  
    // Add event listener for changes in the map's bounds
    map.addListener('bounds_changed', () => {
      const bounds = map.getBounds();
      setMapBounds(bounds);
    });
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  const panTo = useCallback(({ lat, lng, zoom }) => {
      center.current.panTo({ lat, lng });
      if (zoom) {
        center.current.setZoom(zoom);
      }
  }, []);

  const visibleBars = useMemo(() => {
    // Filter bars to only include those in the proximity of the user
    return bars.filter(([name, address, lat, lng]) => {
      if (!mapBounds) return false;
      return mapBounds.contains({ lat, lng });
    });
  }, [mapBounds]);
  
  visibleBars.sort(([name1, address1, lat1, lng1], [name2, address2, lat2, lng2]) => {
    if (!userLocation) return false;
    const distance1 = getDistanceFromLatLonInKm(userLocation.lat, userLocation.lng, lat1, lng1);
    const distance2 = getDistanceFromLatLonInKm(userLocation.lat, userLocation.lng, lat2, lng2);
    return distance1 - distance2;
  });


  //weather data fetch and cache
  useEffect(() => {
    const currentTime = Date.now();
  
    Object.keys(locationCache).forEach(key => {
      if (locationCache[key].timestamp < currentTime - 60 * 60 * 1000) {
        delete locationCache[key];
      }
    });
  
    const newSidebarBars = visibleBars.slice(0, 5);
  
    setSidebarBars(newSidebarBars);
  
    // Fetch weather data for new bars displayed in the sidebar list
    newSidebarBars.forEach(([name, address, lat, lng]) => {
      // Check if weather data for this bar is already cached
      const key = `${lat}&${lng}`;
      if (locationCache[key]) {
        // Use cached weather data
        setBarWeatherData(prevData => ({
          ...prevData,
          [name]: locationCache[key].data
        }));
      } else {
        // Fetch weather data from OpenWeather Current Weather API
        fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${appid}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`An error occurred while fetching weather data: ${response.statusText}`);
            }
            return response.json()
          })
          .then(data => {
            // Cache weather data
            locationCache[key] = {
              data: {
                ...data,
                icon: data.weather[0].icon
              },
              timestamp: currentTime
            };
  
            // Update barWeatherData state variable
            setBarWeatherData(prevData => ({
              ...prevData,
              [name]: locationCache[key].data
            }));
          });
      }
    });
  }, [visibleBars]);

  const handleLocationPermissionResponse = (allowPermission) => {
    setLocationPermission(allowPermission);
    if (allowPermission) {
      // Update minZoom value of the map
      center.current.setOptions({minZoom: 11})

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          panTo({
            lat: latitude,
            lng: longitude,
          });
  
          // Check if bars array is empty or not in the expected format
          if (bars.length === 0) {
            // Handle case where bars array is empty
          } else if (!Array.isArray(bars[0])) {
            // Handle case where bars array is not in the expected format
          } else {
            // Find closest cluster of bars
            const closestClusterIndex = findClosestCluster(latitude, longitude, bars);
            // console.log(closestClusterIndex)
            // Check if closest cluster was found
            if (closestClusterIndex === -1) {
              // Handle case where no closest cluster was found
            } else {
              const closestCluster = bars[closestClusterIndex];
              const closestClusterLat = closestCluster[2];
              const closestClusterLng = closestCluster[3];
              const distance = getDistanceFromLatLonInKm(latitude, longitude, closestClusterLat, closestClusterLng);
  
              // Set zoom level based on distance
               // Set zoom level based on distance
              let zoomLevel;
              if (distance < 1) {
                zoomLevel = 15;
              } else if (distance >= 1 && distance < 2) {
                zoomLevel = 14;
              } else if (distance >= 2 && distance < 3) {
                zoomLevel = 12;
              } else if (distance >= 3 && distance < 5) {
                zoomLevel = 11;
              } else if (distance >= 5 && distance < 10) {
                zoomLevel = 11;
              } else {
                zoomLevel = 10;
              }
              center.current.setZoom(zoomLevel);
            }
          }
        },
        () => null,
        options
      );
    } else {
      setShowAddressModal(true);
      setLocationPermission(true);
    }
  };
  
  //this function handles the infoWindow popup event 
  const handleBarClick = (name, lat, lng, map) => {
    panTo({ lat, lng, zoom: 18 });
  
    // Close the currently open info window
    if (openInfoWindow) {
      openInfoWindow.close();
    }
  
    // Open the info window for the selected bar
    const marker = markers.find(marker => marker.getTitle() === name);
    if (marker) {
      const bar = bars.find(bar => bar[0] === name);
      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div>
                    <h3><a href="${bar[4]}" target="_blank">${name}</a></h3>
                  </div>`,
      });
      infoWindow.open(map, marker);
      setOpenInfoWindow(infoWindow);
    }
  
    // Close the expanded window
    setExpanded(false);
  };
  
  //function to check the lang query string from the url and change the language accordingly

  const getLanguageFromQuerystring = (permittedValues, defaultLanguage) => {
    const querystring = new URLSearchParams(window.location.search);
    const qsLang = querystring.get('lang');
    if (typeof qsLang !== 'string' || qsLang.length === 0 || !permittedValues.includes(qsLang)) {
      return defaultLanguage;
    }
    return qsLang;
  };
  
  const userLanguage = getLanguageFromQuerystring(['en', 'fr', 'fr-CA'], 'en');
  
    
  return (
    <>
     {!locationPermission && (
  <Modal
    className="content"
    isOpen={!locationPermission}
    onRequestClose={() => {}}
  >
    <div className="header-container">
    <img src={
        userLanguage === 'fr' || userLanguage === 'fr-CA'
          ? 'small_logo_french.png'
          : 'small-logo.png'
      } alt="patio ray-dar" />
    </div>
    <h2>
          {userLanguage === 'fr' || userLanguage === 'fr-CA' ? 'Le site web souhaite accéder à votre position actuelle' : 'The website requests access to your current location'}
    </h2>
    <div className="logo-container">
    <img src="logo-red.png" alt="logo" />
    <div className="buttons-container">
      <button
        className="allow-button"
        onClick={() => handleLocationPermissionResponse(true)
        }
        >
        {userLanguage === 'fr' || userLanguage === 'fr-CA' ? 'Autoriser' : 'Allow'}
      </button>
      <button
        className="deny-button"
        onClick={() => handleLocationPermissionResponse(false)}
        >
       {userLanguage === 'fr' || userLanguage === 'fr-CA' ? 'Refuser' : 'Deny'}
      </button>
    </div>
  </div>
  </Modal>
)}

{showAddressModal && (
  <Modal
    className="content"
    isOpen={showAddressModal}
    onRequestClose={() => {}}
  >
    <div className="header-container">
    <img src={
        userLanguage === 'fr' || userLanguage === 'fr-CA'
          ? 'small_logo_french.png'
          : 'small-logo.png'
      } alt="patio ray-dar" />
    </div>
    <div className="input-container">
    <PlacesAutocomplete
        setSelected={setSelected}
        panTo={panTo}
        setShowAddressModal={setShowAddressModal}
        setUserLocation={setUserLocation}
        center={center}
        bars={bars}
        findClosestCluster={findClosestCluster}
        getDistanceFromLatLonInKm={getDistanceFromLatLonInKm}
        userLanguage={userLanguage}
    />
      <div className="container-two">
      <button
      className="search-button"
      onClick={() => {
      setShowAddressModal(false);
      }}
      >
      {userLanguage === 'fr' || userLanguage === 'fr-CA' ? 'Entrez' : 'Enter'}
      </button>
      </div>
    </div>
  </Modal>
)}

<div className="container">
{locationPermission && !showAddressModal && (
  <div className="yellow">
    <img src={
        userLanguage === 'fr' || userLanguage === 'fr-CA'
          ? 'header_desktop_french.png'
          : 'header-desktop.png'
      } alt="Header" className="header-desktop"/>
    <div className="columns">
      <div className="column">
        <h2>{userLanguage === 'fr' || userLanguage === 'fr-CA' ? 'Lieux' : 'Location'}</h2>
        <ul className="location-ul">
          {visibleBars.slice(0, 5).map(([name, address, lat, lng], index) => (
            <li key={index}>
              <h3 onClick={() => handleBarClick(name, lat, lng)}>{name}</h3>
              <p>{address}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="column">
        <h2 className="header-weather">{userLanguage === 'fr' || userLanguage === 'fr-CA' ? 'Météo' : 'Weather'}</h2>
        <ul className="weather-ul">
          {visibleBars.slice(0, 5).map(([name], index) => (
            <li key={index}>
              {barWeatherData[name] && (
                <img
                  className="weather-icon"
                  src={`/${iconMap[barWeatherData[name].icon]}`}
                  alt="Weather icon"
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)}


  <div className="map">
      {locationPermission && !showAddressModal && (
        <div className="places-container">
          <PlacesAutocomplete
          setSelected={setSelected}
          panTo={panTo}
          setShowAddressModal={setShowAddressModal}
          setUserLocation={setUserLocation}
          center={center}
          bars={bars}
          findClosestCluster={findClosestCluster}
          getDistanceFromLatLonInKm={getDistanceFromLatLonInKm}
          />
        </div>
      )}
  <div>
    </div>
    <GoogleMap onLoad={handleMapLoad} zoom={4}  center={centers} options={options} mapContainerClassName="map-container">
    {locationPermission && !showAddressModal && (
  <div className="yellow-two">
    <div className="expand-icon-container" onClick={handleExpandClick}>
      <span className="expand-icon"></span>
    </div>
    <img
      src={
        userLanguage === 'fr' || userLanguage === 'fr-CA'
          ? 'header_mobile_french.png'
          : 'header-mobile.png'
      }
      alt="Header"
      className="header-image"
    />
    <CSSTransition
      in={expanded}
      timeout={300}
      classNames="columns"
      unmountOnExit
    >
      <div className="columns">
        <div className="column">
          <h2 className="location">{userLanguage === 'fr' || userLanguage === 'fr-CA' ? 'Lieux' : 'Location'}</h2>
          <ul className="">
            {visibleBars.slice(0, 5).map(([name, address, lat, lng], index) => (
              <li key={index}>
                <h3 onClick={() => handleBarClick(name, lat, lng)}>{name}</h3>
                <p>{address}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="column">
          <h2>{userLanguage === 'fr' || userLanguage === 'fr-CA' ? 'Météo' : 'Weather'}</h2>
          <ul className="weather-ul">
            {visibleBars.slice(0, 5).map(([name], index) => (
              <li key={index}>
                {barWeatherData[name] && (
                  <img
                    className="weather-icon"
                    src={`/${iconMap[barWeatherData[name].icon]}`}
                    alt="Weather icon"
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </CSSTransition>
  </div>
)}
</GoogleMap>
</div>
</div>
  </>
  );
}

let currentOpenInfoWindow = null;

//function that displays bar icons and clusters onto the map

function addMarkers(map, setSelectedTree, setMarkers) {
  const newMarkers = bars.map(([name, address, lat, lng, link]) => {
    if (lat === null || lng === null) {
      // Handle case where lat or lng is null
      return null;
    }
    const marker = new window.google.maps.Marker({
      position: { lat, lng },
      map,
      title: name,
      icon: {
        url: 'bar.png',
        scaledSize: new window.google.maps.Size(60, 80)
      }
    });
    
    const infoWindow = new window.google.maps.InfoWindow({
      content: `<div>
                  <h3><a href="${link}" target="_blank">${name}</a></h3>
                </div>`,
    });
    
    marker.addListener('click', () => {
      // Close the currently open info window
      if (currentOpenInfoWindow) {
        currentOpenInfoWindow.close();
      }
    
      infoWindow.open(map, marker);
      currentOpenInfoWindow = infoWindow;
      setSelectedTree({ name, address });
    });
    
    
    return marker;
  });

  setMarkers(newMarkers);

  const renderer = {
    render: ({ count, position }) =>
      new window.google.maps.Marker({
        label: { text: String(count), color: "white", fontSize: "25px", labelOrigin: new window.google.maps.Point(12.5, 12.5) },
        icon: {
          url: 'cluster.png',
          scaledSize: new window.google.maps.Size(55, 55),
        },
        position,
        zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
      }),
  };

  new MarkerClusterer({
    renderer,
    markers: newMarkers,
    map,
    algorithm: new SuperClusterAlgorithm({ radius: 200 }),
  });
}

// autocomplete input at the top of the map

const PlacesAutocomplete = ({
  setSelected,
  panTo,
  setShowAddressModal,
  setUserLocation,
  center,
  bars,
  findClosestCluster,
  getDistanceFromLatLonInKm,
  userLanguage,
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      setSelected({ lat, lng });
      setUserLocation({ lat, lng });

       center.current.setOptions({minZoom: 11})

      const closestClusterIndex = findClosestCluster(lat, lng, bars);
      if (closestClusterIndex === -1) {
        // Handle case where no closest cluster was found
      } else {
        const closestCluster = bars[closestClusterIndex];
        const closestClusterLat = closestCluster[2];
        const closestClusterLng = closestCluster[3];
        const distance = getDistanceFromLatLonInKm(lat, lng, closestClusterLat, closestClusterLng);

        // Set zoom level based on distance
        let zoomLevel;
        if (distance < 1) {
          zoomLevel = 15;
        } else if (distance >= 1 && distance < 2) {
          zoomLevel = 14;
        } else if (distance >= 2 && distance < 3) {
          zoomLevel = 12;
        } else if (distance >= 3 && distance < 5) {
          zoomLevel = 11;
        } else if (distance >= 5 && distance < 10) {
          zoomLevel = 11;
        } else {
          zoomLevel = 10;
        }
        center.current.setZoom(zoomLevel);
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
   <Combobox onSelect={handleSelect}>
    <ComboboxInput value={value} onChange={(e) => setValue(e.target.value)} disabled={!ready}
    className="combobox-input" placeholder={
      userLanguage === 'fr' || userLanguage === 'fr-CA'
        ? 'Adresse de recherche'
        : 'Search for address or location'
    } onKeyDown={(e) => {
      if (e.key === "Enter") {
        setShowAddressModal(false);
        setValue("");
      }
    }}/>
    <ComboboxPopover>
      <ComboboxList>
        {status === "OK" && data.map(({place_id, description}) => <ComboboxOption key=
        {place_id} value={description} />)}
      </ComboboxList>
    </ComboboxPopover>
   </Combobox>
  );
};

//finds the closest bar cluster to the current users location

function findClosestCluster(userLat, userLng, barLocations) {
  if (barLocations.length === 0) {
    console.log('barLocations array is empty');
    return -1;
  } else if (!Array.isArray(barLocations[0])) {
    console.log('barLocations array is not in the expected format');
    return -1;
  }

  let minDistance = Infinity;
  let closestClusterIndex = -1;

  barLocations.forEach((cluster, index) => {
    const clusterLat = cluster[2];
    const clusterLng = cluster[3];
    const distance = getDistanceFromLatLonInKm(userLat, userLng, clusterLat, clusterLng);
    if (distance < minDistance) {
      minDistance = distance;
      closestClusterIndex = index;
    }
  });
  return closestClusterIndex;
}

//Calculates distance between the bars 

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; 
  var dLat = deg2rad(lat2-lat1);  
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; 
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}


