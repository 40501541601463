import React from "react";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import App from "./App";

// Set the app element
Modal.setAppElement("#root");

const rootElement = document.getElementById("root");
ReactDOM.createRoot(rootElement).render(<App />);


